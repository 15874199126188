
import Vue from 'vue'

export default Vue.extend({
  name: 'abgabe',

  computed: {
    currentAward(): any {
      return this.$store.state.currentAward
    },
    isBesichtigung(): boolean {
      return this.currentAward.phase === 'besichtigungstermineingabe'
    },
  },

  methods: {
    formatDayAndDate (date: any, addDays: any) {
      const d = new Date(date)
      if (addDays) {
        d.setDate(d.getDate() + 7)
      }
      return d.toLocaleDateString('de', { weekday: 'long' })  + ', ' + d.toLocaleDateString()
    },
  },
});
