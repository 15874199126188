
import Vue from 'vue'
import Abgabe from '../projekt/abgabe.vue'

export default Vue.extend({
  name: 'PreisStart',

  data: () => {
    const allProjects: any[] = []
    return {
      isLoading: true,
      selectedProject: null,
      allProjects,
    }
  },

  components: {
    Abgabe,
  },

  computed: {
    baseUrl () {
      return this.$store.state.baseUrl
    },
    currentAward(): any {
      return this.$store.state.currentAward
    },
    isBesichtigung(): boolean {
      return this.currentAward.phase === 'besichtigungstermineingabe'
    },
    pdfDocuments(): any {
      const { awardType, year } = this.currentAward
      if (this.$store.state.appInfo?.awards?.pdfDocuments[awardType]) {
        return this.$store.state.appInfo?.awards?.pdfDocuments[awardType][year]
      } else {
        return null
      }
    },
    projects() {
      return this.$store.state.projectList
    },
    projectCount() {
      return this.$store.state.projectList.length + 1
    },
  },

  methods: {
    createDraft() {
      this.isLoading = true
      this.$store.dispatch('createDraft', this.currentAward).then(this.init)
      return false
    },

    createDraftFrom() {
      this.isLoading = true
      this.$store.dispatch('createDraftFrom', { ...this.currentAward, id: this.selectedProject }).then(this.init)
      return false
    },

    getPdfPath(p: any, type: string) {
      const { awardType, year } = this.currentAward
      if (!this.pdfDocuments) return ''
      let url = this.baseUrl.slice(0, -1) + this.pdfDocuments[type].url
      url = url.replace('-AWARDTYPE-', awardType)
      url = url.replace('-YEAR-', year)
      url = url.replace('-ID-', p.id)
      return url
    },

    getProjectDetail(project: any) {
      if (project && project.projectDetails) {
        const res = project.projectDetails.filter((pd: any) => {
          return pd.awardType === this.currentAward.awardType
        })
        return res[0]
      }
      return false
    },

    init() {
      let ready = 0
      const go = () => {
        if (++ready === 2) {
          // both requests done
          const res: any[] = []
          this.$store.state.allProjects.forEach((ap: any, i: number) => {
            if (this.$store.state.projectList.filter((p: any) => p.id === ap.id).length === 0) {
              res.push({ id: i, value: ap.id, name: ap.titel + ' vom ' + new Date(ap.created_at).toLocaleDateString() })
            }
          })
          this.allProjects = res
          this.isLoading = false
        }
      }
      this.$store.dispatch('getProjectList', this.currentAward).then(go)
      this.$store.dispatch('getAllProjects').then(go)
      this.$store.commit('setDidSignup', false)
      this.$store.commit('setInfoFromLogin', false)
    },

    isNewAppointment(project: any) {
      if (project && project.projectDetails) {
        const res = project.projectDetails.filter((pd: any) => {
          return pd.awardType === this.currentAward.awardType && pd.year === this.currentAward.year && pd.viewing_appointment_dates_saved
        })
        return res.length === 0
      }
      return false
    },

    isSubmitted(project: any) {
      if (project && project.projectDetails) {
        const res = project.projectDetails.filter((pd: any) => {
          return pd.awardType === this.currentAward.awardType && pd.year === this.currentAward.year && pd.eingereicht
        })
        return res.length > 0
      }
      return false
    },

    isReady(project: any) {
      if (project && project.projectDetails) {
        const res = project.projectDetails.filter((pd: any) => {
          return pd.awardType === this.currentAward.awardType && pd.year === this.currentAward.year && pd.vollstaendig
        })
        return res.length > 0
      }
      return false
    },
  },

  created() {
    this.init()
  },
})
